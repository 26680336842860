import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Input, Row, Col, Button, Label, FormGroup, Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import styled from 'styled-components';

export const Styles = styled.div`
    font-size: 12px;

    table {
tbody > tr:last-child {
    border-bottom: 0;
}
        tr { border: none; }

        th,
        td {
          margin: 0;
          padding-right: 1px;
          padding-left: 1px;
          padding-top: 5px;
          padding-bottom: 5px;
          border-right: solid 1px rgba(224,38,38, 0.2); 
          border-left: solid 1px rgba(224,38,38, 0.2);
          vertical-align: middle;
        }

        th:last-child,
        td:last-child{
            border-right: 0
        }
        th:first-child,
        td:first-child{
            border-left: 0
        }
        
        td{
          padding-left: 5px;
          padding-right: 5px;
          border-bottom: solid 1px rgba(224,38,38, 0.2);
        }
      }

`

 function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const { t } = useTranslation();
    const count = preFilteredRows.length

    return (
        <Input
            className="t-filter-input"
            bsSize="sm"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${t("Table.Find")} ${count} ${t("Table.Records")}...`}
        />
    )
}

function StyledTable({ columns, data }) {
    const { t, i18n } = useTranslation();
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            //fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )


    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        pageOptions,
        page,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes
    },
        useFilters, // useFilters!
        //useGlobalFilter // useGlobalFilter!
        usePagination
        )

    // Render the UI for your table
    return (
        <><Table {...getTableProps()} size="sm" borderless>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.titleHeader ? "title-header" : column.canFilter ? "th-subtitle" : "th-subtitle-no-filter"}
                                style={column.style}
                            >{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (column.isActions ? <th key="action-column" className="action-column">{t("Table.Actions")}...</th> : column.canFilter ?
                            <th {...column.getHeaderProps()}
                                className="th-subtitle-filter"
                                style={column.style}>
                                <div>{column.render('Filter')}</div></th> : null
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? page.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.column.isDateCell && cell.value ? new Date(cell.value).toLocaleString(undefined, {day:'numeric', month:'numeric', year:'numeric'}) : cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    }
                ) : <tr style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}><td colSpan={20}>{t("Button.NoData")}</td></tr>}
            </tbody>
        </Table>
            {rows.length > 0 ?
                <div style={{ marginBottom: "3rem" }}>
                    <Row>
                        <Col sm={4}>
                            <Button className="red-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button className="red-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button className="red-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button className="red-button" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>
                        </Col>
                        <Col sm={4}>
                            <Form inline>
                                <FormGroup>
                                    <Label for="goto">{t("Table.GoTo")}</Label>
                                    <Input
                                        style={{ width: '50px', marginLeft: '5px' }}
                                        name="goto"
                                        bsSize="sm"
                                        type="number"
                                        defaultValue={pageIndex + 1 || 1}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}

                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={4}>
                            <Input type="select"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }} > {
                                    [10, 20, 40, 60, 80, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {t("Table.Show")}{pageSize}
                                        </option>))
                                }
                            </Input>
                            <em>
                                {t("Table.OfPages", { value: pageIndex + 1, total: pageOptions.length})}
                            </em>{' '}<br />
                            <em>
                                {t("Table.OfEntries", { value: page.length, total: rows.length })}
                            </em>
                        </Col>
                    </Row>
                </div> : null}
        </>
    )
}

export default StyledTable;
