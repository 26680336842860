import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';
import AlertPopUp from './AlertPopUp';
import AddUpdateClassButton from './AddUpdateClassButton';
import { getAllCompanyCodes, getAllAdminUsers } from '../store/AdminActions';
import { Row, Col } from 'reactstrap';

const Administration = props => {

    useEffect(() => {
        props.getAllAdminUsers();
    }, []);

    const [redirect, setRedirect] = React.useState(null);
    const [cols, setCols] = React.useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.adminUsersList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.adminUsersList[0])) {
                if (key != "userId") {
                    if (key != "countryCode") {
                        if (key != "isAdmin" && key != 'vendorCode') {
                            setCols(prevCols => [
                                ...prevCols,
                                {
                                    Header: t("Administration.Columns." + key),
                                    accessor: key
                                }
                            ]);
                        }
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Admin")} tabNumber={5} buttonType="delete" currentClass={props.adminUsersList[cell.row.id]} disabled={props.adminUsersList[cell.row.id]?props.adminData.email == props.adminUsersList[cell.row.id].email:false} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    }, [JSON.stringify(props.adminUsersList)]);

    i18n.on('languageChanged', function () {
        if (props.adminUsersList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.adminUsersList[0])) {
                if (key != "userId") {
                    if (key != "countryCode") {
                        if (key != "isAdmin" && key != 'vendorCode') {
                            setCols(prevCols => [
                                ...prevCols,
                                {
                                    Header: t("Administration.Columns." + key),
                                    accessor: key
                                }
                            ]);
                        }
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Admin")} tabNumber={5} buttonType="delete" currentClass={props.adminUsersList[cell.row.id]} disabled={props.adminUsersList[cell.row.id] ? props.adminData.email == props.adminUsersList[cell.row.id].email : false}/>
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    })

    const columns = [{
        Header: t("Titles.Administration"),
        columns: cols,
        titleHeader: true
    }];

    const data = props.adminUsersList;

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    return (
        <div>
            <OptionsMenu active="0" />
            <AddUpdateClassButton tabName={t("Titles.Admin")} tabNumber={5} buttonType="add" currentClass={null} />
            <Styles>
                <StyledTable columns={columns} data={data} />
            </Styles>
            <AlertPopUp type={t("Titles.Admin")} tabNumber={5} />
        </div>
    );
}

export default connect(
    state => {
        return {
            adminUsersList: state.admin.adminUsersList,
            adminData: state.admin.adminData
        }
    },
    dispatch => ({
        getAllAdminUsers: (data) => { dispatch(getAllAdminUsers(data)) }
    })
)(Administration);