import fetch from "cross-fetch";
import * as ActionTypes from "./AdminActionTypes";
import * as constants from "../store/Constants";
import { post, put, get, deleteById } from "./api";

export const actionCreators = {
  updateAuthorization: (authorization) => ({
    type: ActionTypes.updateAuthorizationType,
    payload: authorization,
  }),
  updateReset: (reset) => ({
    type: ActionTypes.updateResetPasswordType,
    payload: reset,
  }),
  responseErrors: (response) => ({
    type: ActionTypes.updateResponseErrorsType,
    payload: response,
  }),
  updateApiResponse: (response) => ({
    type: ActionTypes.updateApiResponseType,
    payload: response,
  }),

  updateNotifications: (response) => ({
    type: ActionTypes.updateNotificationsType,
    payload: response,
  }),
  addNotification: (response) => ({
    type: ActionTypes.addNotificationType,
    payload: response,
  }),
  updateNotification: (response) => ({
    type: ActionTypes.updateNotificationType,
    payload: response,
  }),
  deleteNotification: (response) => ({
    type: ActionTypes.deleteNotificationType,
    payload: response,
  }),

  updateCompanyCodes: (response) => ({
    type: ActionTypes.updateCompanyCodesType,
    payload: response,
  }),
  addCompanyCode: (response) => ({
    type: ActionTypes.addCompanyCodeType,
    payload: response,
  }),
  updateCompanyCode: (response) => ({
    type: ActionTypes.updateCompanyCodeType,
    payload: response,
  }),
  deleteCompanyCode: (response) => ({
    type: ActionTypes.deleteCompanyCodeType,
    payload: response,
  }),

  updateSubjects: (response) => ({
    type: ActionTypes.updateSubjectsType,
    payload: response,
  }),
  addSubject: (response) => ({
    type: ActionTypes.addSubjectType,
    payload: response,
  }),
  updateSubject: (response) => ({
    type: ActionTypes.updateSubjectType,
    payload: response,
  }),
  deleteSubject: (response) => ({
    type: ActionTypes.deleteSubjectType,
    payload: response,
  }),

  updateAllStatus: (response) => ({
    type: ActionTypes.updateAllStatusType,
    payload: response,
  }),
  addStatus: (response) => ({
    type: ActionTypes.addStatusType,
    payload: response,
  }),
  updateStatus: (response) => ({
    type: ActionTypes.updateStatusType,
    payload: response,
  }),
  deleteStatus: (response) => ({
    type: ActionTypes.deleteStatusType,
    payload: response,
  }),

  updateCountries: (response) => ({
    type: ActionTypes.updateCountriesType,
    payload: response,
  }),
  addCountry: (response) => ({
    type: ActionTypes.addCountryType,
    payload: response,
  }),
  updateCountry: (response) => ({
    type: ActionTypes.updateCountryType,
    payload: response,
  }),
  deleteCountry: (response) => ({
    type: ActionTypes.deleteCountryType,
    payload: response,
  }),

  updateCountryCodes: (response) => ({
    type: ActionTypes.updateCountryCodesType,
    payload: response,
  }),
  updateCountryId: (id) => ({
    type: ActionTypes.updateCountryIdType,
    payload: id,
  }),
  updateAdminData: (data) => ({
    type: ActionTypes.updateAdminDataType,
    payload: data,
  }),
  updateActionStatus: (status) => ({
    type: ActionTypes.updateActionStatusType,
    payload: status,
  }),

  updateAllAdminUsers: (response) => ({
    type: ActionTypes.updateAllAdminUsersType,
    payload: response,
  }),
  addAdminUser: (response) => ({
    type: ActionTypes.addAdminUserType,
    payload: response,
  }),
  updateAdminUser: (response) => ({
    type: ActionTypes.updateAdminUserType,
    payload: response,
  }),
  deleteAdminUser: (response) => ({
    type: ActionTypes.deleteAdminUserType,
    payload: response,
  }),

  setImpersonate: (response) => ({
    type: ActionTypes.setImpersonateType,
    payload: response,
  }),
  cleanImpersonate: () => ({ type: ActionTypes.cleanImpersonateType }),
  updateVendorList: (response) => ({
    type: ActionTypes.updateVendorsListType,
    payload: response,
  }),
};

export const updateAuthorization = (data) => (dispatch) => {
  dispatch(actionCreators.updateAuthorization(data));
};

export const clearActionStatus = () => (dispatch) => {
  return dispatch(actionCreators.updateActionStatus(null));
};

export function checkAuthentication() {
  return async (dispatch) => {
    const auth = await window.sessionStorage.getItem(constants.tokenName);
    const formattedAuth = typeof auth === "string" ? JSON.parse(auth) : null;

    formattedAuth
      ? dispatch(actionCreators.updateAuthorization(true))
      : dispatch(actionCreators.updateAuthorization(false));
  };
}

export const logout = () => (dispatch) => {
  sessionStorage.setItem(constants.tokenName, "");
  dispatch(actionCreators.updateAuthorization(false));
  dispatch(actionCreators.updateCountryId(null));
};

export const authenticate = (data) => (dispatch) => {
  return post("user/Authenticate", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        //settoken
        sessionStorage.setItem(
          constants.tokenName,
          JSON.stringify(response.data.token)
        );
        dispatch(actionCreators.updateAuthorization(true));
        dispatch(actionCreators.updateAdminData(response.data));
        dispatch(actionCreators.updateCountryId(data.countryCode));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }

        dispatch(actionCreators.responseErrors(response));
        dispatch(actionCreators.updateAuthorization(false));

        dispatch(updatePopUpResponse(response));
      }
    });
};

export const create = (data) => (dispatch) => {
  return post("user", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateAuthorization(true));
      } else if (response.success == false) {
        var errorList = [];
        response.errors.forEach(function (e) {
          errorList.push(e.message);
        });
        var error = new Error(errorList.join(", "));
        response.error = error;

        dispatch(actionCreators.responseErrors(response));
        dispatch(actionCreators.updateAuthorization(false));
      } else {
        response.error = Object.keys(response.errors).join(", ");

        dispatch(actionCreators.responseErrors(response));
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

export const update = (id, data) => (dispatch) => {
  return put(`user/${id}`, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateAdminData(response.data));
        dispatch(actionCreators.updateActionStatus(true));
      } else if (response.success == false) {
        var errorList = [];
        response.errors.forEach(function (e) {
          errorList.push(e.message);
        });
        var error = new Error(errorList.join(", "));
        response.error = error;

        dispatch(actionCreators.responseErrors(response));
        dispatch(actionCreators.updateActionStatus(false));
      } else {
        response.error = Object.keys(response.errors).join(", ");

        dispatch(actionCreators.responseErrors(response));
        dispatch(actionCreators.updateActionStatus(false));
      }
    });
};

export const forgotPassword = (email, lang, countryCode) => {
  get(`email/forgotPassword/${lang}?email=${email}&countryCode=${countryCode}`);
};

export const resetPassword = (password, token) => (dispatch) => {
  put(`user/resetpassword?password=${password}`, null, token).then(
    (response) => {
      if (response.ok) {
        dispatch(actionCreators.updateReset(true));
      } else {
        dispatch(actionCreators.updateReset(false));
      }
    }
  );
};

function updatePopUpResponse(response) {
  return (dispatch) => {
    dispatch(actionCreators.updateApiResponse(response));
    setTimeout(function () {
      dispatch(actionCreators.updateApiResponse(null));
    }, 5000);
  };
}

//Company code
export const addCompanyCode = (data) => (dispatch) => {
  return post("companycode/PostAsync", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.addCompanyCode(response.data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateCompanyCode = (data) => (dispatch) => {
  return put("companycode/PutAsync/" + data.companyCodeId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateCompanyCode(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteCompanyCode = (id) => (dispatch) => {
  return deleteById("companycode/DeleteAsync/" + id, { id })
    .then((r) => r.json())
    .then((response) => {
      var currentTab = 1;
      if (response.success) {
        dispatch(actionCreators.deleteCompanyCode(id));
        response.data.buttonType = "delete";
        response.data.currentTab = currentTab;
      } else {
        response.data = { buttonType: "delete", currentTab: currentTab };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const getAllCompanyCodes = () => (dispatch) => {
  return get("companycode/GetAll")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateCompanyCodes(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

//Notification
export const addNotification = (data) => (dispatch) => {
  return post("notification/PostAsync", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.addNotification(response.data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateNotification = (data) => (dispatch) => {
  return put("notification/PutAsync/" + data.notificationId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateNotification(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteNotification = (id) => (dispatch) => {
  return deleteById("notification/DeleteAsync/" + id, { id })
    .then((r) => r.json())
    .then((response) => {
      var currentTab = 3;
      if (response.success) {
        dispatch(actionCreators.deleteNotification(id));
        response.data.buttonType = "delete";
        response.data.currentTab = currentTab;
      } else {
        response.data = { buttonType: "delete", currentTab: currentTab };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const getAllNotifications = () => (dispatch) => {
  return get("notification/GetAll")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateNotifications(response.data));
      } else {
        if (response.success == false) {
          dispatch(actionCreators.updateNotifications([]));
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

//Subject
export const addSubject = (data) => (dispatch) => {
  return post("subject/PostAsync", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.addSubject(response.data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateSubject = (data) => (dispatch) => {
  return put("subject/PutAsync/" + data.subjectId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateSubject(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteSubject = (id) => (dispatch) => {
  return deleteById("subject/DeleteAsync/" + id, { id })
    .then((r) => r.json())
    .then((response) => {
      var currentTab = 2;
      if (response.success) {
        dispatch(actionCreators.deleteSubject(id));
        response.data.buttonType = "delete";
        response.data.currentTab = currentTab;
      } else {
        response.data = { buttonType: "delete", currentTab: currentTab };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const getAllSubjects = () => (dispatch) => {
  return get("subject/GetAll")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateSubjects(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

//Status
export const addStatus = (data) => (dispatch) => {
  return post("status/PostAsync", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.addStatus(response.data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateStatus = (data) => (dispatch) => {
  return put("status/PutAsync/" + data.statusId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateStatus(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteStatus = (id) => (dispatch) => {
  return deleteById("status/DeleteAsync/" + id, { id })
    .then((r) => r.json())
    .then((response) => {
      var currentTab = 4;
      if (response.success) {
        dispatch(actionCreators.deleteStatus(id));
        response.data.buttonType = "delete";
        response.data.currentTab = currentTab;
      } else {
        response.data = { buttonType: "delete", currentTab: currentTab };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const getAllStatus = () => (dispatch) => {
  return get("status/GetAll")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateAllStatus(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

//Country
export const addCountry = (data) => (dispatch) => {
  return post("country/PostAsync", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.addCountry(response.data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateCountry = (data) => (dispatch) => {
  return put("country/PutAsync/" + data.countryId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateCountry(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteCountry = (id) => (dispatch) => {
  return deleteById("country/DeleteAsync/" + id, { id })
    .then((r) => r.json())
    .then((response) => {
      var currentTab = 0;
      if (response.success) {
        dispatch(actionCreators.deleteCountry(id));
        response.data.buttonType = "delete";
        response.data.currentTab = currentTab;
      } else {
        response.data = { buttonType: "delete", currentTab: currentTab };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const getAllCountries = () => (dispatch) => {
  return get("country/GetAll")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateCountries(response.data));
        dispatch(getAllCountryCodes());
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

export const getAllCountryCodes = () => (dispatch) => {
  return get("country/GetAllCodes")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateCountryCodes(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

export const changeCountry = (data) => (dispatch) => {
  return post("user/country", data.countryCode).then((response) => {
    if (response.ok) {
      dispatch(actionCreators.updateCountryId(data.countryCode));
      dispatch(actionCreators.updateActionStatus(true));
    } else {
      dispatch(actionCreators.updateActionStatus(false));
    }
  });
};

//Admin Users
export const addAdminUser = (data) => (dispatch, getState) => {
  return post("user", data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        if (data.countryCode == getState().admin.countryId)
          dispatch(actionCreators.addAdminUser(response.data));
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
      } else {
        response.data = {
          buttonType: data.buttonType,
          currentTab: data.currentTab,
        };
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const updateAdminUser = (data) => (dispatch) => {
  return put("user/" + data.countryId, data)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateAdminUser(data));
        response.data.buttonType = data.buttonType;
        response.data.currentTab = data.currentTab;
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }

      dispatch(updatePopUpResponse(response));
    });
};

export const deleteAdminUser = (id) => (dispatch) => {
  return (
    deleteById("user/" + id, { id })
      //.then(r => r.json())
      .then((response) => {
        var currentTab = 5;
        if (response.ok) {
          dispatch(actionCreators.deleteAdminUser(id));
          response.success = true;
        } else {
          response.success = false;
          response.error = true;
        }
        response.data = { buttonType: "delete", currentTab: currentTab };
        dispatch(updatePopUpResponse(response));
      })
  );
};

export const getAllAdminUsers = () => (dispatch) => {
  return get("user")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        // maybe update global state in the future?
        dispatch(actionCreators.updateAllAdminUsers(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
        dispatch(actionCreators.updateAuthorization(false));
      }
    });
};

export const getImpersonate = (taxId) => (dispatch) => {
  return get(`user/impersonate?taxId=${taxId}`)
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.setImpersonate(response.data));
      } else {
        if (response.success == false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        }

        dispatch(actionCreators.responseErrors(response));
        dispatch(updatePopUpResponse(response));
      }
    });
};

export const cleanImpersonate = () => (dispatch) => {
  return dispatch(actionCreators.cleanImpersonate());
};

//vendors list

export const getAllVendors = () => (dispatch) => {
  return get("country/GetVendorsByCountry")
    .then((r) => r.json())
    .then((response) => {
      if (response.success) {
        dispatch(actionCreators.updateVendorList(response.data));
      } else {
        if (response.success === false) {
          var errorList = [];
          response.errors.forEach(function (e) {
            errorList.push(e.message);
          });
          var error = new Error(errorList.join(", "));
          response.error = error;
        } else {
          response.error = Object.keys(response.errors).join(", ");
        }
      }
    });
};

export const cleanResponseErrors = () => (dispatch) => {
  dispatch(actionCreators.responseErrors(null));
};
