import React, { useEffect, useState }from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { getAllCountries } from '../store/AdminActions';

const DropdownCountryList = props => {
    const { t, i18n } = useTranslation();
    const { register, errors, handleSubmit, control, watch, setValue } = useForm();


    useEffect(() => {
        if (!props.countriesList.length > 0) {
            props.getAllCountries();
        }
    }, [JSON.stringify(props.countriesList)]);

    const options = props.countriesList.map(c => {
        return { value: c.countryCode, label: t("CountryCode." + c.countryCode) }
    });

    
    return (
        <FormGroup>
                <Controller
                    as={Select}
                    isSearchable={true}
                    id="country"
                    name="country"
                    control={control}
                    placeholder={`${t("LogIn.Enter")} ${t("LogIn.Country")}...`}
                    options={options}
                    register={register}
                    onChange={([selected]) => {
                        props.setCountry(selected)
                        return selected
                    }}
                    rules={{
                             validate: value => value !== `${t("LogIn.Enter")} ${t("LogIn.Country")}...`,
                            required: true
                            }}
                    styles={{ control: styles => ({ ...styles, borderColor: errors.country ? '#dc3545' : 'rgb(204, 204, 204)' }) }}
                    />
                <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>
                    {t("Validations.IsRequired", { value: t("LogIn.Country") })}
                </div>
        </FormGroup>
    )
}
export default connect(
    state => {
        return {
            countriesList: state.admin.countriesList,
        }
    },
    dispatch => ({
        getAllCountries: () => { dispatch(getAllCountries()) },
    })
)(DropdownCountryList);