import * as constants from '../store/Constants';
import fetch from 'cross-fetch';
import { getToken, login } from '../config/AdConfig';
const baseUrl = `${window.location.origin}/api/`;

const makeAuthHeader = () => {
    let token = getToken();
    if (token) {
        return `Bearer ${token}`;
    }
    else {
        //El usuario no esta loggeado, lo redireccionamos al login de AD
        login();
    }
}

export const post = (url, body) => {
    let authorization = makeAuthHeader();

    return fetch(baseUrl + url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": authorization
        }
    });
};

export const put = (url, body, resetToken) => {
    let authorization = resetToken ? `Bearer ${resetToken}` : makeAuthHeader();

    return fetch(baseUrl + url, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": authorization
        }
    });
};

export const get = (url) => {
    let authorization = makeAuthHeader();

    return fetch(baseUrl + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authorization
        }
    });
};

export const deleteById = (url) => {
    let authorization = makeAuthHeader();

    return fetch(baseUrl + url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authorization
        }
    });
};