import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';
import AlertPopUp from './AlertPopUp';
import AddUpdateClassButton from './AddUpdateClassButton';
import { getAllNotifications } from '../store/AdminActions';
import { Row, Col } from 'reactstrap';

const Notifications = props => {

    useEffect(() => {
        props.getAllNotifications();
    }, [JSON.stringify(props.countryId)]);

    const [redirect, setRedirect] = React.useState(null);
    const [cols, setCols] = React.useState([]);
    const { t, i18n } = useTranslation();

    const filterDate = (rows, id, filterValue) => {
        return rows.filter(r => {
            let rowDate = new Date(r.original[id]).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric' })

            return rowDate.includes(filterValue);
        });
    }

    useEffect(() => {
        if (props.notificationList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.notificationList[0])) {
                if (key != "notificationId") {
                    if (key != "countryCode") {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key,
                                isDateCell: ["startDate", "endDate"].includes(key) ? true : false,
                                filter: ["startDate", "endDate"].includes(key) ? filterDate : ''
                            }
                        ]);
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Notification")} tabNumber={3} buttonType="update" currentClass={props.notificationList[cell.row.id]} />
                                </Col>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Notification")} tabNumber={3} buttonType="delete" currentClass={props.notificationList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    }, [JSON.stringify(props.notificationList)]);

    i18n.on('languageChanged', function () {
        if (props.notificationList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.notificationList[0])) {
                if (key != "notificationId") {
                    if (key != "countryCode") {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key,
                                isDateCell: key == "startDate" ? true : key == "endDate" ? true : false
                            }
                        ]);
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Notification")} tabNumber={3} buttonType="update" currentClass={props.notificationList[cell.row.id]} />
                                </Col>
                                <Col sm={4} style={{ marginLeft: "0.2rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Notification")} tabNumber={3} buttonType="delete" currentClass={props.notificationList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    })

    const columns = [{
        Header: t("Titles.Notifications"),
        columns: cols,
        titleHeader: true
    }];

    const data = props.notificationList;

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
        return (
            <div>
                <OptionsMenu active="4" />
                <AddUpdateClassButton tabName={t("Titles.Notification")} tabNumber={3} buttonType="add" currentClass={null} />
                <Styles>
                    <StyledTable columns={columns} data={data} />
                </Styles>
                <AlertPopUp type={t("Titles.Notification")} tabNumber={3} />
            </div>
        );
}

export default connect(
    state => {
        return {
            notificationList: state.admin.notificationList
        }
    },
    dispatch => ({
        getAllNotifications: (data) => { dispatch(getAllNotifications(data)) }
    })
)(Notifications);