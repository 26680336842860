import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardTitle, CardText, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { resetPassword } from '../store/AdminActions';

const ResetPassword = props => {
    const [redirect, setRedirect] = React.useState(null);
    const { t, i18n } = useTranslation();
    const { register, errors, handleSubmit, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const query = new URLSearchParams(props.location.search);

    const onSubmit = data => {
        props.resetPassword(data.password, query.get("token"));
    }

    useEffect(() => {
        if (props.resetted) {
            setRedirect('/');
        }
    }, [props.resetted]);

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    else if (!query.get("token")) {
        return <Redirect to={{ pathname: '/' }} />
    }
    return (
        <div className="component-container">
            <h3 className="options-menu-active">{t("Titles.ForgotPassword")}</h3>
            <Row>
                <Col sm="12" className="some-content">
                    <Card body style={{ padding: "3rem" }}>
                        <CardTitle>{t("ResetPassword.CardTitle")}</CardTitle>
                        <CardText style={{ whiteSpace: "pre-wrap" }}>{t("ResetPassword.CardBody")}</CardText>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                                <Label for="password">{t("LogIn.Password")}{' '}<em className="form-subtext">({t("NewUserLogIn.PasswordSubText")})</em></Label>
                                <Input type="password" name="password" id="password" placeholder={`${t("LogIn.Enter")} ${t("LogIn.Password")}`}
                                    innerRef={register({
                                        required: t("Validations.IsRequired", { value: t("LogIn.Password") }),
                                        minLength: {
                                            value: 5,
                                            message: t("Validations.MinLength", { property: t("LogIn.Password"), value: 5 })
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: t("Validations.MaxLength", { property: t("LogIn.Password"), value: 20 })
                                        }
                                    })}
                                    invalid={errors.password ? true : false} />
                                <FormFeedback>
                                    {errors.password && errors.password.message}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="passwordValidate">{t("NewUserLogIn.Reenter")}{' '}{t("LogIn.Password")}</Label>
                                <Input type="password" name="passwordValidate" id="passwordValidate" placeholder={`${t("NewUserLogIn.Reenter")} ${t("LogIn.Password")}`}
                                    innerRef={register({
                                        validate: value => value === password.current || t("Validations.Equal", { value: t("LogIn.Password") })
                                    })}
                                    invalid={errors.passwordValidate ? true : false} />
                                <FormFeedback>
                                    {errors.passwordValidate && errors.passwordValidate.message}
                                </FormFeedback>
                            </FormGroup>
                            {props.isPasswordReseted === false ? 
                                <FormGroup>
                                    <FormFeedback valid={false} className="op-error">
                                        {t("ResetPassword.Error")}
                                    </FormFeedback>
                                </FormGroup>
                                : <></>
                            }
                            <Row>
                                <Col sm="4"></Col>
                                <Col sm="4">
                                    <Button block={true} className="red-button" type="submit">{t("Button.Ok")}</Button>
                                </Col>
                                <Col sm="4">
                                    <Button block={true} color="secondary" onClick={() => { setRedirect("/") }}>{t("Button.Cancel")}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default connect(
    state => {
        return {
            resetted: state.admin.isPasswordReseted
        }
    },
    dispatch => ({
        resetPassword: (password, token) => { dispatch(resetPassword(password, token)) }
    })
)(ResetPassword);
