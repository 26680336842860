import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Container, Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { forgotPassword } from '../store/AdminActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getAllCountries } from '../store/AdminActions';


const ForgotPassword = props => {
    const [redirect, setRedirect] = React.useState(null);
    const [forgotPasswordValue, setForgotPassword] = React.useState(null);
    const [selectedValue, setSelectedValue] = useState(3);
    const { t, i18n } = useTranslation();
    const { register, errors, handleSubmit } = useForm();

    const onSubmit = data => {
        forgotPassword(data.recoveryEmail, i18n.languages[0], selectedValue);
        setForgotPassword('/');
    }

    useEffect(() => {
        if (!props.countriesList.length > 0) {
            props.getAllCountries();
        }
    }, [JSON.stringify(props.countriesList)]);

    const options = props.countriesList.map(c => {
        return { value: c.countryCode, label: t("CountryCode." + c.countryCode) }
    });

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    else if (forgotPasswordValue) {
        return (
            <div className="component-container">
                <h3 className="options-menu-active">{t("Titles.ForgotPassword")}</h3>
                <Row>
                    <Col sm="12" className="some-content">
                        <Card body>
                            <Row className="justify-content-center">
                                <div>
                                    <FontAwesomeIcon style={{ 'color': 'green', 'width': '135px', 'height': '135px', 'marginBottom': '16px' }} icon={faCheckCircle} />
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{t("ForgotPassword.Info")}</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <div>
                                    <a href={forgotPasswordValue}>{t("ForgotPassword.Return")}</a>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div className="component-container">
            <h3 className="options-menu-active">{t("Titles.ForgotPassword")}</h3>
            <Row>
                <Col sm="12" className="some-content">
                    <Card body style={{ padding: "3rem" }}>
                        <CardTitle>{t("ForgotPassword.CardTitle")}</CardTitle>
                        <CardText style={{ whiteSpace: "pre-wrap" }}>{t("ForgotPassword.CardBody")}</CardText>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                                <Label for="country">{t("LogIn.Country")}</Label>
                                <Select
                                    isSearchable={true}
                                    name="country"
                                    placeholder={`${t("LogIn.Enter")} ${t("LogIn.Country")}...`}
                                    options={options}
                                    value={options.filter(obj => obj.value === selectedValue)}
                                    onChange={e => { setSelectedValue(e.value); }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="recoveryEmail">{t("ForgotPassword.CardSubText")}</Label>
                                <Input type="email" name="recoveryEmail" id="recoveryEmail" placeholder={`${t("LogIn.Enter")} e-mail`}
                                    innerRef={register({ required: true, maxLength: 60 })}
                                    invalid={errors.recoveryEmail ? true : false} />
                            </FormGroup>
                            <Row>
                                <Col sm="4"></Col>
                                <Col sm="4">
                                    <Button block={true} className="red-button" type="submit">{t("Button.Ok")}</Button>
                                </Col>
                                <Col sm="4">
                                    <Button block={true} color="secondary" onClick={() => { setRedirect("/login") }}>{t("Button.Cancel")}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default connect(
    state => {
        return {
            countryId: state.admin.countryId,
            countriesList: state.admin.countriesList
        }
    },
    dispatch => ({
        getAllCountries: () => { dispatch(getAllCountries()) }
    })
)(ForgotPassword);
