import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import ChangeCountry from './ChangeCountry';
import { logout } from '../config/AdConfig';

function NavMenu(props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { t } = useTranslation();
    const [pModal, psetModal] = useState(false);
    const pToggle = () => psetModal(!pModal);

    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light >
          <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img src="./scj_logo.svg" alt="logo" className="logo" />
                        
                    </NavbarBrand>
                    {props.countryId ?
                        <>
                            
                        <NavbarToggler onClick={toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                            <div>
                                <ul className="navbar-nav flex-grow" style={{ marginLeft: '2.5rem'}}>
                                    <NavItem>
                                        <div className="text-dark pointer" onClick={() => pToggle()}>{t("ChangeCountry.NavMenu")}{' '}<FontAwesomeIcon icon={faGlobe} /></div>
                                    </NavItem>
                                    <NavItem>
                                        <div className="text-dark pointer" style={{ float: "left", marginLeft: "10px" }} onClick={logout}>{t("NavMenu.Exit")}{' '}<FontAwesomeIcon icon={faSignOutAlt} /></div>
                                    </NavItem>
                                    </ul>
                                    <em style={{ fontSize: "14px", marginLeft: '2.5rem' }}>{t("NavMenu.LoggedFrom") + t("CountryCode." + props.countryId)}</em>
                            </div>
                            <Modal style={{marginTop: '20vh'}} isOpen={pModal} toggle={pToggle}>
                                <ChangeCountry toggle={pToggle} />
                            </Modal>
                        </Collapse></> : <div className="menu-text-align"><h3>{t("Home.Header")}</h3></div>}
          </Container>
        </Navbar>
      </header>
    );
}

export default connect(
    state => {
        return {
            countryId: state.admin.countryId,
            countriesList: state.admin.countriesList
        }
    }
)(NavMenu);