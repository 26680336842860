import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col, Button, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import LanguageToggle from './LanguageToggle';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faInfoCircle, faCogs } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [pModal, psetModal] = useState(false);
    const toggle = () => setModal(!modal);
    const pToggle = () => psetModal(!pModal);

    return (
        <footer className="footer" style={{width: "100%"}}>
            <Container>
                <div style={{ }}>
                    <hr style={{ marginTop: "0" }} className="footer-button" />
                    
                    <div className="footer-pipe">
                        <span>&#169;</span> {new Date().getFullYear()} {t("NavMenu.Footer")}
                    </div>
                    {' '}<LanguageToggle /> 
                </div>
            </Container>
        </footer>
    )
}
export default Footer;
