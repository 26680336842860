import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import * as constants from './store/Constants';
import LogIn from './components/LogIn';
import Home from './components/Home';
import { getToken } from './config/AdConfig';

export default function (ComposedComponent, requirements) {
    const Authentication = props => {
        const currentUser = getToken();
        if (!currentUser) {
            if (window.location.pathname == "/" || window.location.pathname == "/login") { }
            else {
                return <Redirect to={{ pathname: "/login" }} />
            }
        }
        else {
            var req = requirements.map(r => props[r])
            var meetsRequirements = req.every(r => r);
            if (!meetsRequirements) {
                return <LogIn {...props} />
            }
            else {
                return <ComposedComponent {...props} />
            }
        }
    }

    function mapStateToProps(state) {
        return {
            countryId: state.admin.countryId,
            authorization: state.admin.authorization
        };
    }

    return connect(mapStateToProps)(Authentication);
}