import React from 'react';
import { connect } from 'react-redux';

const Home = props => (
  <div>
    <p>Log In to be able to edit this page!</p>
  </div>
);

export default connect()(Home);
