import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';
import AlertPopUp from './AlertPopUp';
import AddUpdateClassButton from './AddUpdateClassButton';
import { getAllCompanyCodes } from '../store/AdminActions';
import { Row, Col } from 'reactstrap';

const CompanyCodes = props => {

    useEffect(() => {
        props.getAllCompanyCodes();
    }, [props.countryId]);

    const [redirect, setRedirect] = React.useState(null);
    const [cols, setCols] = React.useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.companyCodeList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.companyCodeList[0])) {
                if (key != "companyCodeId") {
                    if (key != "countryCode") {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key
                            }
                        ]);
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={6} style={{ textAlign: "center", padding: 0, paddingLeft: "1rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.CompanyCode")} tabNumber={1} buttonType="update" currentClass={props.companyCodeList[cell.row.id]} />
                                </Col>
                                <Col sm={6} style={{ textAlign: "center", padding: 0 }}>
                                    <AddUpdateClassButton tabName={t("Titles.CompanyCode")} tabNumber={1} buttonType="delete" currentClass={props.companyCodeList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    }, [JSON.stringify(props.companyCodeList)]);

    i18n.on('languageChanged', function () {
        if (props.companyCodeList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.companyCodeList[0])) {
                if (key != "companyCodeId") {
                    if (key != "countryCode") {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key
                            }
                        ]);
                    }
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={6} style={{ textAlign: "center", padding: 0, paddingLeft: "1rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.CompanyCode")} tabNumber={1} buttonType="update" currentClass={props.companyCodeList[cell.row.id]} />
                                </Col>
                                <Col sm={6} style={{ textAlign: "center", padding: 0 }}>
                                    <AddUpdateClassButton tabName={t("Titles.CompanyCode")} tabNumber={1} buttonType="delete" currentClass={props.companyCodeList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    })

    const columns = [{
        Header: t("Titles.CompanyCodes"),
        columns: cols,
        titleHeader: true
    }];

    const data = props.companyCodeList;
    
    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
        return (
            <div>
                <OptionsMenu active="2" />
                <AddUpdateClassButton tabName={t("Titles.CompanyCode")} tabNumber={1} buttonType="add" currentClass={null} />{/*{ name: "hola", contentEN: "me", contentES: "llamo", contentPT: "fiona"} } />*/}
                <Styles>
                    <StyledTable columns={columns} data={data} />
                </Styles>
                <AlertPopUp type={t("Titles.CompanyCode")} tabNumber={1} />
            </div>
        );
}

export default connect(
    state => {
        return {
            companyCodeList: state.admin.companyCodesList,
            countryId: state.admin.countryId
        }
    },
    dispatch => ({
        getAllCompanyCodes: (data) => { dispatch(getAllCompanyCodes(data)) }
    })
)(CompanyCodes);
