import React, { useState } from 'react';
import { Button, Collapse, ListGroup, ListGroupItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

function LanguageToggle() {
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <Dropdown direction="up" isOpen={isOpen} toggle={toggle} className="language-toggle">
            <DropdownToggle caret>
                <FontAwesomeIcon icon={faGlobe} />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => changeLanguage('en')}>en</DropdownItem>
                <DropdownItem onClick={() => changeLanguage('es')}>es</DropdownItem>
                <DropdownItem onClick={() => changeLanguage('pt')}>pt</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default LanguageToggle;