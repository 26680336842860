import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardTitle, Button, Form, Tooltip} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { changeCountry, getAllCountries, cleanResponseErrors } from '../store/AdminActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import DropdownCountryList from './DropdownCountryList';

const Loader = () => (
    <div className="loading-app" style={{ textAlign: "center", fontSize: "100px", verticalAlign: "middle" }}>
        <FontAwesomeIcon icon={faSpinner} spin color="#d9534f" />
    </div>
);

const LogIn = props => {
    const { t, i18n } = useTranslation();
    const { register, errors, handleSubmit, control, setValue } = useForm();
    const [country, setCountry] = useState()
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen)

    useEffect(() => {
        if (!props.countriesList.length > 0) {
            props.getAllCountries();
        }
    }, [JSON.stringify(props.countriesList)]);

    if (props.countryId) return <Redirect to={{ pathname: "/companycodes" }} />

    return (
        props.countriesList.length > 0 ?  <div className="component-container">
            <h3 className="options-menu-active">{t("Titles.ChangeCountry")}</h3>
            <Row style={{ marginBottom:'4rem' }}>
                <Col sm="12" className="some-content">
                    <Card body style={{ padding: "3rem" }}>
                        <CardTitle>{t("ChangeCountry.SubTitle")}</CardTitle>
                        <Form onSubmit={handleSubmit((e) => {
                            props.changeCountry({
                                countryCode: document.getElementById("country").lastChild.value
                            })
                        })}
                        >
                            <DropdownCountryList setCountry={setCountry} country={country} />
                            <Row >
                                <Col sm="4"></Col>
                                <Col sm="4"></Col>
                                <Col sm="4">
                                    <span id="foo">
                                    <Button style={country ? {} : { pointerEvents: 'none' }} block={true} disabled={!country} className="red-button" type="submit" >{t("LogIn.Ok")}</Button>
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                        <Tooltip id="tooltip__login" placement="right" autohide={true} isOpen={tooltipOpen && !country} target="foo" toggle={toggle} trigger="click">
                            {t("LogIn.Validate")}
                        </Tooltip>
                    </Card>
                </Col>
            </Row>
        </div> : <Loader />
    );
}

export default connect(
    state => {
        return {
            isAuthenticated: state.admin.isAuthenticated,
            countryId: state.admin.countryId,
            countriesList: state.admin.countriesList,
            apiResponse: state.admin.apiResponse,
            responseErrors: state.admin.responseErrors
        }
    },
    dispatch => ({
        changeCountry: (data) => { dispatch(changeCountry(data)) },
        getAllCountries: () => { dispatch(getAllCountries()) },
        cleanResponseErrors: () => { dispatch(cleanResponseErrors()) }
    })
)(LogIn);
