import React from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card } from 'reactstrap';


export default props => {
    const { t } = useTranslation();

    return (
        <Card body>
            <Row className="justify-content-center">
                <div>
                    <FontAwesomeIcon style={{ color: '#FFCC00', width: '135px', height: '135px', marginBottom: '16px' }} icon={faExclamationTriangle} />
                </div>
            </Row>
            <Row className="justify-content-center">
            <div>
                <h4 style={{ marginBottom: '16px' }}>{t("NotFound.Info")}</h4>
                </div>
            </Row>
            <Row className="justify-content-center">
                <div>
                    <a href="/">{t("ForgotPassword.Return")}</a>
                </div>
            </Row>
        </Card>
    );
}