import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye} from '@fortawesome/free-solid-svg-icons'
import { useForm, Controller } from 'react-hook-form';

import {
    addNotification, updateNotification, deleteNotification,
    addCompanyCode, updateCompanyCode, deleteCompanyCode,
    addSubject, updateSubject, deleteSubject,
    addStatus, updateStatus, deleteStatus,
    addCountry, updateCountry, deleteCountry, addAdminUser, updateAdminUser, deleteAdminUser,
    getImpersonate
} from '../store/AdminActions';
import Select from 'react-select';


const ModalExample = (props) => {
    const {
        buttonType,
        tabName,
        tabNumber,
        currentClass
    } = props;

    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const { register, errors, handleSubmit, setValue, control } = useForm();

    const toggle = () => setModal(!modal);
    const toggleDelete = () => setModalDelete(!modalDelete);
    const [input, setInput] = useState({})
    const [defInput, setDefInput] = useState({})

    const handleInputChange = (e) => {
        input[e.currentTarget.name] = e.currentTarget.value;
        setInput(input)
    }

    useEffect(() => {
        if (currentClass) {
            setDefInput(currentClass);
        } 
    });

    const sendClass = () => {
        var object = {
            name: input["name"] || defInput["name"],
            contentEN: input["contentEN"] || defInput["contentEN"],
            content: input["content"] || defInput["content"],
            countryCode: props.countryId,
            buttonType: buttonType,
            currentTab: tabNumber
        }

        if (tabNumber == 0) {
            //countries
            if (buttonType == "delete") {
                props.deleteCountry(currentClass.countryId);

                toggleDelete();
            }
            else {
                object["supplierCode"] = input["supplierCode"] || defInput["supplierCode"];
                object["countryCode"] = document.getElementById("countryCode").lastChild.value;
                if (currentClass) {
                    object["countryId"] = defInput["countryId"];
                    props.updateCountry(object);
                    setInput({});
                }
                else {
                    props.addCountry(object)
                    setInput({});
                }

                toggle();
            }
        } else if (tabNumber == 1) {
            //company codes
            if (buttonType == "delete") {
                props.deleteCompanyCode(currentClass.companyCodeId);

                toggleDelete();
            }
            else {
                if (currentClass) {
                    object["companyCodeId"] = currentClass.companyCodeId;
                    props.updateCompanyCode(object);
                    setInput({});
                }
                else {
                    props.addCompanyCode(object)
                    setInput({});
                }

                toggle();
            }
        } else if (tabNumber == 2) {
            //subjects
            if (buttonType == "delete") {
                props.deleteSubject(currentClass.subjectId);

                toggleDelete();
            }
            else {
                object["requestAccount"] = input["requestAccount"] || defInput["requestAccount"];
                if (currentClass) {
                    object["subjectId"] = currentClass.subjectId;
                    props.updateSubject(object);
                    setInput({});
                }
                else {
                    props.addSubject(object)
                    setInput({});
                }

                toggle();
            }
        } else if (tabNumber == 3) {
            //notifications
            if (buttonType == "delete") {
                props.deleteNotification(currentClass.notificationId);

                toggleDelete();
            }
            else {
                object["startDate"] = input["startDate"] || defInput["startDate"];
                object["endDate"] = input["endDate"] || defInput["endDate"];
                if (currentClass) {
                    object["notificationId"] = currentClass.notificationId;
                    props.updateNotification(object);
                    setInput({});
                }
                else {
                    props.addNotification(object)
                    setInput({});
                }

                toggle();
            }
        } else if (tabNumber == 4) {
            //status
            object["name"] = input["name"] || defInput["name"];
            object["nameEN"] = input["nameEN"] || defInput["nameEN"];
            object["description"] = input["description"] || defInput["description"];
            object["descriptionEN"] = input["descriptionEN"] || defInput["descriptionEN"];
            if (buttonType == "delete") {
                props.deleteStatus(currentClass.statusId);

                toggleDelete();
            }
            else {
                if (currentClass) {
                    object["statusId"] = currentClass.statusId;
                    props.updateStatus(object);
                    setInput({});
                }
                else {
                    props.addStatus(object)
                    setInput({});
                }

                toggle();
            }
        } else if (tabNumber == 5) {
            //users
            var user = {
                buttonType: buttonType,
                email: input["email"],
                password: input["password"],
                currentTab: tabNumber
                //countryCode: document.getElementById("country").lastChild.value//input["country"]
            };
            if (buttonType == "delete") {
                props.deleteAdminUser(currentClass.userId);

                toggleDelete();
            }
            else {
                if (currentClass) {
                    user["userId"] = currentClass.userId;
                    props.updateAdminUser(user);
                }
                else {
                    user["countryCode"] = document.getElementById("country").lastChild.value;
                    props.addAdminUser(user)
                    setInput({})
                }

                toggle();
            }
        }
        //setInput({});
    }

    const countryCodeOptions = props.countryCodesList.map(c => {
        return { value: c.countryCodeId, label: t("CountryCode." + c.countryCodeId)}
    });

    const countryOptions = props.countriesList.map(c => {
        return { value: c.countryCode, label: t("CountryCode." + c.countryCode) }
    });
    
    return (
        <div>
            {
                buttonType == "add" ?
                    <Button color="danger" onClick={toggle} style={{ marginBottom: "1rem", float: "right" }} >{`${t("AllTabs.Add")} ${tabName}`}</Button> :
                buttonType == "update" ?
                    <Button color="danger" onClick={toggle} style={{ margin: "2px", padding: "0.200rem 0.50rem" }}><FontAwesomeIcon icon={faEdit} /></Button> :
                buttonType == "delete" ?
                    <Button disabled={props.disabled} color="danger" onClick={toggleDelete} style={{ margin: "2px", padding: "0.200rem 0.50rem",  marginLeft: tabNumber==5? "1.5rem":"2px" }}><FontAwesomeIcon icon={faTrash} /></Button> :
                buttonType == "impersonate" ?
                    <Button color="danger" title={t("Titles.Impersonate")} onClick={() => {props.getImpersonate(currentClass.sysId)}} style={{ margin: "2px", padding: "0.200rem 0.50rem" }}><FontAwesomeIcon icon={faEye} /></Button> :
                null
            }
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{`${buttonType == "add" ? t("AllTabs.Add") : t("AllTabs.Update")} ${tabName}`}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(sendClass)}>
                        {tabNumber != 5 && tabNumber != 4 ?
                            <FormGroup>
                                <Label for="name">{t("AllTabs.name")}</Label>
                                <Input type="text" name="name" id="name" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.name")}`} onChange={handleInputChange} defaultValue={defInput["name"]}
                                    innerRef={register({ required: true})}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.name") })}
                                </FormFeedback>
                            </FormGroup> : null}
                        {tabNumber == 0 ? 
                            <FormGroup>
                                <Label for="countryCode">{t("LogIn.Country")}</Label>
                                <Controller
                                    as={Select}
                                    isSearchable={true}
                                    id="countryCode"
                                    name="countryCode"
                                    control={control}
                                    placeholder={`${t("LogIn.Enter")} ${t("LogIn.Country")}...`}
                                    defaultValue={defInput['countryCode'] ? { value: defInput['countryCode'], label: t("CountryCode." + defInput['countryCode'])}: null}
                                    options={countryCodeOptions}
                                    register={register}
                                    setValue={setValue}
                                    rules={{
                                        required: true,
                                        validate: value => value !== `${t("LogIn.Enter")} ${t("LogIn.Country")}...`
                                    }}
                                    styles={{ control: styles => ({ ...styles, borderColor: errors.countryCode ? '#dc3545' : 'rgb(204, 204, 204)' }) }}
                                                              />
                                <div className="invalid-feedback" style={{ display: errors.countryCode ? 'block' : 'none' }}>
                                    {t("Validations.IsRequired", { value: t("LogIn.Country") })}
                                </div>
                            </FormGroup>
                        : null}
                        {
                            tabNumber == 4 ?
                            <><FormGroup>
                                <Label for="nameEN">{t("AllTabs.nameEN")}</Label>
                                    <Input type="textarea" name="nameEN" id="nameEN" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.nameEN")}`} onChange={handleInputChange} defaultValue={defInput["nameEN"]} 
                                    innerRef={register({ required: true })}
                                    invalid={errors.nameEN ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.nameEN") })}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">{t("AllTabs.name")}</Label>
                                        <Input type="textarea" name="name" id="name" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.name")}`} onChange={handleInputChange} defaultValue={defInput["name"]}
                                    innerRef={register({ required: true })}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.name") })}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="descriptionEN">{t("AllTabs.descriptionEN")}</Label>
                                        <Input type="textarea" name="descriptionEN" id="descriptionEN" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.descriptionEN")}`} onChange={handleInputChange} defaultValue={defInput["descriptionEN"]}
                                    innerRef={register({ required: true })}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.descriptionEN") })}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">{t("AllTabs.description")}</Label>
                                        <Input type="textarea" name="description" id="description" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.description")}`} onChange={handleInputChange} defaultValue={defInput["description"]}
                                    innerRef={register({ required: true })}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.description") })}
                                </FormFeedback>
                            </FormGroup>
                            </>
                                : tabNumber == 5 ? <>
                                    <FormGroup>
                                        <Label for="country">{t("LogIn.Country")}</Label>
                                        <Controller
                                            as={Select}
                                            isSearchable={true}
                                            id="country"
                                            name="country"
                                            control={control}
                                            placeholder={`${t("LogIn.Enter")} ${t("LogIn.Country")}...`}
                                            options={countryOptions}
                                            register={register}
                                            setValue={setValue}
                                            rules={{
                                                required: true,
                                                validate: value => value !== `${t("LogIn.Enter")} ${t("LogIn.Country")}...`
                                            }}
                                            styles={{ control: styles => ({ ...styles, borderColor: errors.country ? '#dc3545' : 'rgb(204, 204, 204)' }) }}
                                        />
                                        <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>
                                            {t("Validations.IsRequired", { value: t("LogIn.Country") })}
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">{t("NewUserLogIn.Mail")}</Label>
                                        <Input type="email" name="email" id="email" placeholder={`${t("LogIn.Enter")} ${t("NewUserLogIn.Mail")}`} onChange={handleInputChange} defaultValue={defInput["email"]}
                                            innerRef={register({ required: true })}
                                            invalid={errors.email ? true : false} />
                                        <FormFeedback>
                                            {t("Validations.IsRequired", { value: t("NewUserLogIn.Mail") })}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="examplePassword">{t("LogIn.Password")}</Label>
                                        <Input type="password" name="password" id="password" placeholder={`${t("LogIn.Enter")} ${t("LogIn.Password")}`} onChange={handleInputChange}
                                            innerRef={register({ required: true })}
                                            invalid={errors.password ? true : false} />
                                        <FormFeedback>
                                            {t("Validations.IsRequired", { value: t("LogIn.Password") })}
                                        </FormFeedback>
                                    </FormGroup>
                                </> :
                                <>{tabNumber != 1 &&
                                    (<FormGroup>
                                        <Label for="contentEN">{t("AllTabs.contentEN")}</Label>
                                        <Input type="textarea" name="contentEN" id="contentEN" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.contentEN")}`} onChange={handleInputChange} defaultValue={defInput["contentEN"]}
                                            innerRef={register({ required: true })}
                                            invalid={errors.name ? true : false} />
                                        <FormFeedback>
                                            {t("Validations.IsRequired", { value: t("AllTabs.contentEN") })}
                                        </FormFeedback>
                                     </FormGroup>)}
                                    <FormGroup>
                                        <Label for="content">{t("AllTabs.content")}</Label>
                                            <Input type="textarea" name="content" id="content" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.content")}`} onChange={handleInputChange} defaultValue={defInput["content"]}
                                            innerRef={register({ required: true })}
                                            invalid={errors.name ? true : false} />
                                        <FormFeedback>
                                            {t("Validations.IsRequired", { value: t("AllTabs.content") })}
                                        </FormFeedback>
                                    </FormGroup>
                                    </>
                        }
                        {tabNumber == 3 ?
                            <>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="startDate">{t("AllTabs.startDate")}</Label>
                                            <Input type="date" name="startDate" id="startDate" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.StartDate")}`} onChange={handleInputChange} defaultValue={defInput["startDate"] ? defInput["startDate"].substr(0, 10) : null}
                                                innerRef={register({ required: true })}
                                                invalid={errors.name ? true : false} />
                                            <FormFeedback>
                                                {t("Validations.IsRequired", { value: t("AllTabs.startDate") })}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="endDate">{t("AllTabs.endDate")}</Label>
                                            <Input type="date" name="endDate" id="endDate" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.EndDate")}`} onChange={handleInputChange} defaultValue={defInput["endDate"] ? defInput["endDate"].substr(0, 10) : null}
                                                innerRef={register({ required: true })}
                                                invalid={errors.name ? true : false} />
                                            <FormFeedback>
                                                {t("Validations.IsRequired", { value: t("AllTabs.endDate") })}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : null
                        }
                        {tabNumber == 0 ?
                            <FormGroup>
                                <Label for="supplierCode">{t("AllTabs.supplierCode")}</Label>
                                <Input type="text" name="supplierCode" id="supplierCode" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.supplierCode")}`} onChange={handleInputChange} defaultValue={defInput["supplierCode"]}
                                    innerRef={register({ required: true })}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.supplierCode") })}
                                </FormFeedback>
                            </FormGroup>
                            : null}
                        {tabNumber == 2 ?
                            <FormGroup>
                                <Label for="requestAccount">{t("AllTabs.requestAccount")}</Label>
                                <Input type="email" name="requestAccount" id="requestAccount" placeholder={`${t("LogIn.Enter")} ${t("AllTabs.requestAccount").toLocaleLowerCase()}`} onChange={handleInputChange} defaultValue={defInput["requestAccount"]}
                                    innerRef={register({ required: true })}
                                    invalid={errors.name ? true : false} />
                                <FormFeedback>
                                    {t("Validations.IsRequired", { value: t("AllTabs.name") })}
                                </FormFeedback>
                            </FormGroup>
                            : null
                        }
                        <Row>
                            <Col sm="6"></Col>
                            <Col sm="2">
                                <Button type="submit" className="red-button">{t("Button.Ok")}</Button>
                            </Col>
                            <Col sm="2">
                                <Button color="secondary" onClick={toggle}>{t("Button.Cancel")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalDelete} toggle={toggleDelete}>
                <ModalHeader toggle={toggleDelete}>{`${t("AllTabs.Delete")} ${tabName}`}</ModalHeader>
                <ModalBody>
                    {t("AllTabs.Confirm")}<strong>{t("AllTabs.ConfirmBold")}</strong>{t("AllTabs.ConfirmQuestion")}
                </ModalBody>
                <ModalFooter>
                    <Button className="red-button" onClick={sendClass} >{t("Button.Ok")}</Button>{' '}
                    <Button color="secondary" onClick={toggleDelete}>{t("Button.Cancel")}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default connect(
    state => {
        return {
            apiResponse: state.admin.apiResponse,
            countryCodesList: state.admin.countryCodesList,
            countriesList: state.admin.countriesList,
            impersonateData: state.admin.impersonateData
        }
    },
    dispatch => ({
        addNotification: (data) => { dispatch(addNotification(data)) },
        updateNotification: (data) => { dispatch(updateNotification(data)) },
        deleteNotification: (id) => { dispatch(deleteNotification(id)) },

        addCompanyCode: (data) => { dispatch(addCompanyCode(data)) },
        updateCompanyCode: (data) => { dispatch(updateCompanyCode(data)) },
        deleteCompanyCode: (id) => { dispatch(deleteCompanyCode(id)) },

        addSubject: (data) => { dispatch(addSubject(data)) },
        updateSubject: (data) => { dispatch(updateSubject(data)) },
        deleteSubject: (id) => { dispatch(deleteSubject(id)) },

        addStatus: (data) => { dispatch(addStatus(data)) },
        updateStatus: (data) => { dispatch(updateStatus(data)) },
        deleteStatus: (id) => { dispatch(deleteStatus(id)) },

        addCountry: (data) => { dispatch(addCountry(data)) },
        updateCountry: (data) => { dispatch(updateCountry(data)) },
        deleteCountry: (id) => { dispatch(deleteCountry(id)) },

        addAdminUser: (data) => { dispatch(addAdminUser(data)) },
        updateAdminUser: (data) => { dispatch(updateAdminUser(data)) },
        deleteAdminUser: (id) => { dispatch(deleteAdminUser(id)) },

        getImpersonate: (taxId) => {dispatch(getImpersonate(taxId))}
    })
)(ModalExample);