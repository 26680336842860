import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';
import AlertPopUp from './AlertPopUp';
import AddUpdateClassButton from './AddUpdateClassButton';
import { getAllVendors, cleanImpersonate } from '../store/AdminActions';
import { Row, Col } from 'reactstrap';

const Vendors = props => {
    
    useEffect(() => {
        props.getAllVendors(); 
    }, [JSON.stringify(props.countryId)]);

    useEffect(() => {
        if(props.impersonateData){
            document.getElementById("impersonateLink").click()
            props.cleanImpersonate()
        }
    },[props.impersonateData])

    const [redirect, setRedirect] = useState(null);
    const [cols, setCols] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.vendorsList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.vendorsList[0])) {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key
                            }
                        ]);
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={12} style={{ textAlign: "center", padding: 0}}>
                                    <AddUpdateClassButton tabName={t("Titles.Vendors")} tabNumber={1} buttonType="impersonate" currentClass={props.vendorsList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    }, [JSON.stringify(props.vendorsList)]);

    i18n.on('languageChanged', function () {
        if (props.vendorsList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.vendorsList[0])) {
                        setCols(prevCols => [
                            ...prevCols,
                            {
                                Header: t("AllTabs." + key),
                                accessor: key
                            }
                        ]);
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={12} style={{ textAlign: "center", padding: 0}}>
                                    <AddUpdateClassButton tabName={t("Titles.Vendors")} tabNumber={1} buttonType="impersonate" currentClass={props.vendorsList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    })

    const columns = [{
        Header: t("Titles.Vendors"),
        columns: cols,
        titleHeader: true
    }];

    const data = props.vendorsList;
    
    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
        return (
            <div>
                {props.impersonateData ? <a id="impersonateLink" href={`${props.impersonateData.redirectUrl}?token=${props.impersonateData.token}`} target="_blank"></a> : null}
                <OptionsMenu active="6" />
                <Styles>
                    <StyledTable columns={columns} data={data} />
                </Styles>
                <AlertPopUp type={t("Titles.Vendors")} tabNumber={1} />
            </div>
        );
}

export default connect(
    state => {
        return {
            vendorsList: state.admin.vendorsList,
            countryId: state.admin.countryId,
            countryCode: state.admin.countryCode,
            impersonateData: state.admin.impersonateData
        }
    },
    dispatch => ({
        getAllVendors: (data) => { dispatch(getAllVendors(data)) },
        cleanImpersonate: () => {dispatch(cleanImpersonate())}
    })
)(Vendors);
