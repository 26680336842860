import { AuthenticationContext, AdalConfig } from 'react-adal';
import { decode } from 'querystring';

const adalConfig = {
    tenant: process.env.REACT_APP_AD_TENANT,
    clientId: process.env.REACT_APP_AD_CLIENTID,
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    endpoints: {
        api: process.env.REACT_APP_AD_APIENDPOINT
    },
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const userContext = () => authContext.getCachedUser();

export const logout = () => authContext.logOut();

export const login = () => authContext.login();