import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Container, Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { create, getAllCountries } from '../store/AdminActions';
import Select from 'react-select';

const NewUserLogIn = props => {
    const [redirect, setRedirect] = React.useState(null);
    const { t, i18n } = useTranslation();
    const [input, setInput] = useState({})
    const [selectedValue, setSelectedValue] = useState(3);

    useEffect(() => {
        if (!props.countriesList.length > 0) {
            props.getAllCountries();
        }
    }, []);

    const handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    })

    const handleSelectChange = (e) => setInput({
        ...input,
        country: e.value
    })

    const submitNewUser = () => {
        props.create({
            userName: input["userName"],
            email: input["email"],
            password: input["password"],
            isAdmin: true,
            countryCode: input["country"]
        })
    }

    const options = props.countriesList.map(c => {
        return { value: c.countryCode, label: t("CountryCode." + c.countryCode) }
    });

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    return (
        <div className="component-container">
            <h3 className="options-menu-active">{t("Titles.NewUserLogIn")}</h3>
            <Row>
                <Col sm="12" className="some-content">
                    <Card body style={{ padding: "3rem" }}>
                        <CardTitle>{t("NewUserLogIn.CardTitle")}</CardTitle>
                        <Form>
                            <FormGroup>
                                <Label for="country">{t("LogIn.Country")}</Label>
                                <Select
                                    isSearchable={true}
                                    name="country"
                                    placeholder={`${t("LogIn.Enter")} ${t("LogIn.Country")}...`}
                                    options={options}
                                    value={options.filter(obj => obj.value === input["country"])}
                                    onChange={handleSelectChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">{t("NewUserLogIn.Mail")}{' '}<em className="form-subtext">({t("NewUserLogIn.MailSubText")})</em></Label>
                                <Input type="email" name="email" id="exampleEmail" placeholder={`${t("LogIn.Enter")} ${t("NewUserLogIn.Mail")}`} onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="examplePassword">{t("LogIn.Password")}</Label>
                                <Input type="password" name="password" id="examplePassword" placeholder={`${t("LogIn.Enter")} ${t("LogIn.Password")}`} onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="examplePassword">{`${t("NewUserLogIn.Reenter")} ${t("LogIn.Password")}`}{' '}<em className="form-subtext">({t("NewUserLogIn.PasswordSubText")})</em></Label>
                                <Input type="password" name="password" id="examplePassword" placeholder={`${t("LogIn.Enter")} ${t("LogIn.Password")}`} />
                            </FormGroup>
                            <Row>
                                <Col sm="4"></Col>
                                <Col sm="4">
                                    <Button block={true} className="red-button" onClick={submitNewUser}>{t("Button.Ok")}</Button>
                                </Col>
                                <Col sm="4">
                                    <Button block={true} color="secondary" onClick={() => { setRedirect("/login") }}>{t("Button.Cancel")}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col sm="12" className="some-content">
                    <h4>{t("NewUserLogIn.NewUser")}</h4>
                    <p style={{ whiteSpace: "pre-wrap" }}>{t("NewUserLogIn.CardBody")}</p>
                    
                </Col>
            </Row>
        </div>
    );
}

export default connect(
    state => {
        return {
            isAuthenticated: state.admin.isAuthenticated,
            countryId: state.admin.countryId,
            countriesList: state.admin.countriesList
        }
    },
    dispatch => ({
        create: (data) => { dispatch(create(data)) },
        getAllCountries: () => { dispatch(getAllCountries()) }
    })
)(NewUserLogIn);
