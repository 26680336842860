export const updateAuthorizationType = "UPDATE_AUTHORIZATION";
export const updateResetPasswordType = "UPDATE_RESET_PASSWORD";
export const updateResponseErrorsType = "UPDATE_RESPONSE_ERRORS";
export const updateApiResponseType = "UPDATE_API_RESPONSE";

export const updateNotificationsType = "UPDATE_NOTIFICATIONS";
export const addNotificationType = "ADD_NOTIFICATION";
export const updateNotificationType = "UPDATE_NOTIFICATION";
export const deleteNotificationType = "DELETE_NOTIFICATION";

export const updateCompanyCodesType = "UPDATE_COMPANY_CODES";
export const addCompanyCodeType = "ADD_COMPANY_CODE";
export const updateCompanyCodeType = "UPDATE_COMPANY_CODE";
export const deleteCompanyCodeType = "DELETE_COMPANY_CODE";

export const updateSubjectsType = "UPDATE_SUBJECTS";
export const addSubjectType = "ADD_SUBJECT";
export const updateSubjectType = "UPDATE_SUBJECT";
export const deleteSubjectType = "DELETE_SUBJECT";

export const updateAllStatusType = "UPDATE_ALL_STATUS";
export const addStatusType = "ADD_STATUS";
export const updateStatusType = "UPDATE_STATUS";
export const deleteStatusType = "DELETE_STATUS";

export const updateCountriesType = "UPDATE_COUNTRIES";
export const addCountryType = "ADD_COUNTRY";
export const updateCountryType = "UPDATE_COUNTRY";
export const deleteCountryType = "DELETE_COUNTRY";

export const updateCountryCodesType = "UPDATE_COUNTRY_CODES";
export const updateCountryIdType = "UPDATE_COUNTRY_ID";
export const updateAdminDataType = "UPDATE_ADMIN_DATA";
export const updateActionStatusType = "UPDATE_ACTION_STATUS";

export const updateAllAdminUsersType = "UPDATE_ADMIN_USERS";
export const addAdminUserType = "ADD_ADMIN_USER";
export const updateAdminUserType = "UPDATE_ADMIN_USER";
export const deleteAdminUserType = "DELETE_ADMIN_USER";

export const setImpersonateType = "GET_IMPERSONATE_DATA";
export const cleanImpersonateType = "CLEAN_IMPERSONATE_DATA";
export const updateVendorsListType = "UPDATE_VENDOR_LIST";