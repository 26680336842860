import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from './Footer';

export default props => (
  <div>
    <NavMenu />
        <Container style={{ marginBottom: window.location.pathname == "/" ? "0" : "90px" }}>
        {props.children}
    </Container>
    <Footer />
  </div>
);
