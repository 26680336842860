import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
.init({
    debug: true,
    react: { useSuspense: false },
    returnObjects: true,
    lng: navigator.language,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // react already safes from xss
    }
  });

export default i18n;