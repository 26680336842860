import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import LogIn from './components/LogIn';
import Logout from './components/Logout';
import ForgotPassword from './components/ForgotPassword';
import NewUserLogIn from './components/NewUserLogIn';
import CompanyCodes from './components/CompanyCodes';
import Administration from './components/Administration';
import VendorData from './components/VendorData';
import Subjects from './components/Subjects';
import Notifications from './components/Notifications';
import Status from './components/Status';
import Countries from './components/Countries';
import WithRequirements from './WithRequirements';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';
import CacheBuster from './CacheBuster';
import Vendors from './components/Vendors';

export default () => (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }

            return (
                <Layout>
                    <Switch>
                        <Route exact path='/' component={LogIn} />
                        <Route path='/login' component={LogIn} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/newuser' component={NewUserLogIn} />
                        <Route path='/recoverpassword' component={ForgotPassword} />
                        <Route path='/resetpassword' component={ResetPassword} />
                        <Route path='/administration' component={WithRequirements(Administration, ["countryId"])} />
                        <Route path='/vendordata' component={WithRequirements(VendorData, ["countryId"])} />
                        <Route path='/countries' component={WithRequirements(Countries, ["countryId"])} />
                        <Route path='/companycodes' component={WithRequirements(CompanyCodes, ["countryId"])} />
                        <Route path='/subjects' component={WithRequirements(Subjects, ["countryId"])} />
                        <Route path='/notifications' component={WithRequirements(Notifications, ["countryId"])} />
                        <Route path='/status' component={WithRequirements(Status, ["countryId"])} />
                        <Route path='/vendors' component={WithRequirements(Vendors, ["countryId"])} />
                        <Route component={NotFound} status={404} />
                    </Switch>
                </Layout>
            );
        }}
    </CacheBuster>
);
