import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardTitle, Button, Form, ModalBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import { update, clearActionStatus, changeCountry } from '../store/AdminActions';
import DropdownCountryList from './DropdownCountryList';

const Preferences = props => {
    const { t } = useTranslation();
    const { register, errors, handleSubmit, watch } = useForm();
    
    const [country, setCountry] = useState()

    const onCancel = () => {
        props.resetUpdated();
        props.toggle();
    }

    return (
        <>
            <ModalBody>
                <Card body style={{ padding: "3rem" }}>
                    <CardTitle>{t("ChangeCountry.Title")}</CardTitle>
                    <Form onSubmit={handleSubmit((e) => {
                            props.changeCountry({
                                countryCode: document.getElementById("country").lastChild.value
                            })
                            props.toggle()
                        })} >
                        <DropdownCountryList setCountry={setCountry} country={country}/>    
                        <Row>
                            <Col sm="4"></Col>
                            <Col sm="4">
                                <Button block={true} className="red-button" disabled={!country} type="submit">{t("Button.Ok")}</Button>
                            </Col>
                            <Col sm="4">
                                <Button block={true} color="secondary" onClick={onCancel}>{t("Button.Cancel")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </ModalBody>
        </>
    );
}

export default connect(
    state => {
        return {
            countryCode: state.admin.countryId,
            adminData: state.admin.adminData,
            updated: state.admin.actionCompleted
        }
    },
    dispatch => ({
        update: (id, data) => { dispatch(update(id, data)) },
        resetUpdated: () => { dispatch(clearActionStatus()) },
        changeCountry: (id) => {dispatch(changeCountry(id))}
    })
)(Preferences);
