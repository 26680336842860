import * as ActionTypes from "./AdminActionTypes";

export const reducer = (state = {
    isAuthenticated: null,
    isPasswordReseted: null,
    responseErrors: null,
    apiResponse: null,
    countryId: null,
    adminData: null,
    actionCompleted: null,
    notificationList: [],
    companyCodesList: [],
    subjectsList: [],
    statusList: [],
    countriesList: [],
    countryCodesList: [],
    adminUsersList: [],
    vendorsList: [],
    impersonateData: null

}, action) => {
    switch (action.type) {
        case ActionTypes.updateAuthorizationType:
            return { ...state, isAuthenticated: action.payload }

        case ActionTypes.updateResetPasswordType:
            return { ...state, isPasswordReseted: action.payload }

        case ActionTypes.updateResponseErrorsType:
            return { ...state, responseErrors: action.payload }

        case ActionTypes.updateApiResponseType:
            return { ...state, apiResponse: action.payload }

        case ActionTypes.updateCountryIdType:
            return { ...state, countryId: action.payload };

        case ActionTypes.updateAdminDataType:
            let data = {
                email: action.payload.email,
                userId: action.payload.userId,
                isAdmin: action.payload.isAdmin
            }
            return { ...state, adminData: data };

        case ActionTypes.updateActionStatusType:
            return { ...state, actionCompleted: action.payload };

        // Company Codes
        case ActionTypes.updateCompanyCodesType:
            return { ...state, companyCodesList: action.payload }

        case ActionTypes.addCompanyCodeType:
            var newCompanyCode = {
                companyCodeId: action.payload.companyCodeId,
                countryCode: action.payload.countryCode,
                name: action.payload.name,
                content: action.payload.content,
                requestNumber: action.payload.requestNumber
            }
            var addList = state.companyCodesList.concat(newCompanyCode);
            return { ...state, companyCodesList: addList }

        case ActionTypes.updateCompanyCodeType:
            var companyCode = action.payload;
            var companyCodesList = state.companyCodesList.map((item) => {
                if (item.companyCodeId == companyCode.companyCodeId) {
                    item.name = companyCode.name;
                    item.content = companyCode.content;
                    item.contentEN = companyCode.contentEN;
                }
                return item;
            });
            return { ...state, companyCodesList: companyCodesList }
      
        case ActionTypes.deleteCompanyCodeType:
            var companyCodesList = state.companyCodesList.filter(l => l.companyCodeId != action.payload);
            return { ...state, companyCodesList: companyCodesList }


        // Notifications
        case ActionTypes.updateNotificationsType:
            return { ...state, notificationList: action.payload }

        case ActionTypes.addNotificationType:
            var newNotification = {
                notificationId: action.payload.notificationId,
                countryCode: action.payload.countryCode,
                name: action.payload.name,
                content: action.payload.content,
                contentEN: action.payload.contentEN,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
            var addList = state.notificationList.concat(newNotification);
            return { ...state, notificationList: addList }

        case ActionTypes.updateNotificationType:
            var notification = action.payload;
            var notificationList = state.notificationList.map((item) => {
                if (item.notificationId == notification.notificationId) {
                    item.name = notification.name;
                    item.content = notification.content;
                    item.contentEN = notification.contentEN;

                    item.startDate = notification.startDate;
                    item.endDate = notification.endDate;
                }
                return item;
            });
            return { ...state, notificationList: notificationList }

        case ActionTypes.deleteNotificationType:
            var deleteList = state.notificationList.filter(l => l.notificationId != action.payload);
            return { ...state, notificationList: deleteList }

        // Subjects
        case ActionTypes.updateSubjectsType:
            return { ...state, subjectsList: action.payload }

        case ActionTypes.addSubjectType:
            var newSubject = {
                subjectId: action.payload.subjectId,
                countryCode: action.payload.countryCode,
                name: action.payload.name,
                content: action.payload.content,
                contentEN: action.payload.contentEN,
                requestAccount: action.payload.requestAccount
            }
            var addList = state.subjectsList.concat(newSubject);
            return { ...state, subjectsList: addList }

        case ActionTypes.updateSubjectType:
            var subject = action.payload;
            var subjectsList = state.subjectsList.map((item) => {
                if (item.subjectId == subject.subjectId) {
                    item.name = subject.name;
                    item.content = subject.content;
                    item.contentEN = subject.contentEN;
                    item.requestAccount = subject.requestAccount;
                }
                return item;
            });
            return { ...state, subjectsList: subjectsList }

        case ActionTypes.deleteSubjectType:
            var subjectsList = state.subjectsList.filter(l => l.subjectId != action.payload);
            return { ...state, subjectsList: subjectsList }

        // Status
        case ActionTypes.updateAllStatusType:
            return { ...state, statusList: action.payload }

        case ActionTypes.addStatusType:
            var newStatus = {
                statusId: action.payload.statusId,
                countryCode: action.payload.countryCode,
                name: action.payload.name,
                nameEN: action.payload.nameEN,
                description: action.payload.description,
                descriptionEN: action.payload.descriptionEN
            }
            var addList = state.statusList.concat(newStatus);
            return { ...state, statusList: addList }

        case ActionTypes.updateStatusType:
            var status = action.payload;
            var statusList = state.statusList.map((item) => {
                if (item.statusId == status.statusId) {
                    item.name = status.name;
                    item.nameEN= status.nameEN;
                    item.description = status.description;
                    item.descriptionEN = status.descriptionEN;
                }
                return item;
            });
            return { ...state, statusList: statusList }

        case ActionTypes.deleteStatusType:
            var statusList = state.statusList.filter(l => l.statusId != action.payload);
            return { ...state, statusList: statusList }

        // Country
        case ActionTypes.updateCountriesType:
            return { ...state, countriesList: action.payload }

        case ActionTypes.addCountryType:
            var newCountry = {
                countryId: action.payload.countryId,
                countryCode: action.payload.countryCode,
                name: action.payload.name,
                content: action.payload.content,
                contentEN: action.payload.contentEN,
                supplierCode: action.payload.supplierCode
            }
            var addList = state.countriesList.concat(newCountry);
            return { ...state, countriesList: addList }

        case ActionTypes.updateCountryType:
            var status = action.payload;
            var statusList = state.countriesList.map((item) => {
                if (item.countryId == status.countryId) {
                    item.name = status.name;
                    item.countryCode = status.countryCode;
                    item.content = status.content;
                    item.contentEN = status.contentEN;
                    item.supplierCode = status.supplierCode;
                }
                return item;
            });
            return { ...state, countriesList: statusList }

        case ActionTypes.deleteCountryType:
            var statusList = state.countriesList.filter(l => l.countryId != action.payload);
            return { ...state, countriesList: statusList }


        case ActionTypes.updateCountryCodesType:
            return { ...state, countryCodesList: action.payload }

        // Users
        case ActionTypes.updateAllAdminUsersType:
            return { ...state, adminUsersList: action.payload }

        case ActionTypes.addAdminUserType:
            var newAdminUser = {
                userId: action.payload.userId,
                sysId: action.payload.sysId,
                email: action.payload.email,
                password: action.payload.password,
                notifyPayment: action.payload.notifyPayment,
                countryCode: action.payload.countryCode,
                isAdmin: action.payload.isAdmin
            }
            var addList = state.adminUsersList.concat(newAdminUser);
            return { ...state, adminUsersList: addList }

        case ActionTypes.updateAdminUserType:
            var user = action.payload;
            var adminUsersList = state.adminUsersList.map((item) => {
                if (item.userId == user.userId) {
                    item.email = user.email;
                }
                return item;
            });
            return { ...state, adminUsersList: adminUsersList }

        case ActionTypes.deleteAdminUserType:
            var adminUsersList = state.adminUsersList.filter(l => l.userId != action.payload);
            return { ...state, adminUsersList: adminUsersList }
   
        case ActionTypes.setImpersonateType:
            return {...state, impersonateData: action.payload}
            
        case ActionTypes.cleanImpersonateType: 
            return {...state, impersonateData: null}
        //vendors list

        case ActionTypes.updateVendorsListType:
            return { ...state, vendorsList: action.payload }

        default:
            return state;
    }
}