import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';


const OptionsMenu = props => {
    const [redirect, setRedirect] = React.useState(null);
    const { t } = useTranslation();

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    return (
        <div className="options-menu-container">
            {/* <div className={props.active == 0 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 0 ? null : setRedirect("/administration")}>{t("Titles.Administration")}</div> */}
            <div className={props.active == 1 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 1 ? null : setRedirect("/countries")}>{t("Titles.Countries")}</div>
            <div className={props.active == 2 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 2 ? null : setRedirect("/companycodes")}>{t("Titles.CompanyCodes")}</div>
            <div className={props.active == 3 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 3 ? null : setRedirect("/subjects")}>{t("Titles.Subjects")}</div>
            <div className={props.active == 4 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 4 ? null : setRedirect("/notifications")}>{t("Titles.Notifications")}</div>
            <div className={props.active == 5 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 5 ? null : setRedirect("/status")}>{t("Titles.Status")}</div>
            <div className={props.active == 6 ? "options-menu-active" : "options-menu"} onClick={() => props.active == 6 ? null : setRedirect("/vendors")}>{t("Titles.Vendors")}</div>
        </div>
    );
}

export default connect()(OptionsMenu);
