import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';
import AddUpdateClassButton from './AddUpdateClassButton';
import { Row, Col } from 'reactstrap';
import AlertPopUp from './AlertPopUp';
import { getAllCountries } from '../store/AdminActions';

const Countries = props => {
    useEffect(() => {
        props.getAllCountries();
    }, []);

    const [redirect, setRedirect] = React.useState(null);
    const [cols, setCols] = React.useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.countriesList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.countriesList[0])) {
                if (key != "countryId") {
                    setCols(prevCols => [
                        ...prevCols,
                        {
                            Header: t("AllTabs." + key),
                            accessor: key
                        }
                    ]);
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={6} style={{ textAlign: "center", padding: 0, paddingLeft: "1rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Country")} tabNumber={0} buttonType="update" currentClass={props.countriesList[cell.row.id]} />
                                </Col>
                                <Col sm={6} style={{ textAlign: "center", padding: 0 }}>
                                    <AddUpdateClassButton tabName={t("Titles.Country")} tabNumber={0} buttonType="delete" currentClass={props.countriesList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    }, [JSON.stringify(props.countriesList)]);

    i18n.on('languageChanged', function () {
        if (props.countriesList.length > 0) {
            setCols([]);
            for (let key of Object.keys(props.countriesList[0])) {
                if (key != "countryId") {
                    setCols(prevCols => [
                        ...prevCols,
                        {
                            Header: t("AllTabs." + key),
                            accessor: key
                        }
                    ]);
                }
            }

            setCols(prevCols => [
                ...prevCols,
                {
                    Header: "",
                    accessor: "actions",
                    Cell: (cell) => {
                        return (
                            <Row>
                                <Col sm={6} style={{ textAlign: "center", padding: 0, paddingLeft: "1rem" }}>
                                    <AddUpdateClassButton tabName={t("Titles.Country")} tabNumber={0} buttonType="update" currentClass={props.countriesList[cell.row.id]} />
                                </Col>
                                <Col sm={6} style={{ textAlign: "center", padding: 0 }}>
                                    <AddUpdateClassButton tabName={t("Titles.Country")} tabNumber={0} buttonType="delete" currentClass={props.countriesList[cell.row.id]} />
                                </Col>
                            </Row>)
                    },
                    isActions: true
                }
            ]);
        }
    })

    const columns = [{
        Header: t("Titles.Countries"),
        columns: cols,
        titleHeader: true
    }];

    const data = props.countriesList;

    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
    return (
        <div>
            <OptionsMenu active="1" />
            <AddUpdateClassButton tabName={t("Titles.Country")} tabNumber={0} buttonType="add" currentClass={null} />
            <Styles>
                <StyledTable columns={columns} data={data} />
            </Styles>
            <AlertPopUp type={t("Titles.Country")} tabNumber={0} />
        </div>
    );
}

export default connect(
    state => {
        return {
            countriesList: state.admin.countriesList
        }
    },
    dispatch => ({
        getAllCountries: (data) => { dispatch(getAllCountries(data)) }
    })
)(Countries);