import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const AlertPopUp = (props) => {
    const { t, i18n } = useTranslation();
    if (props.apiResponse && props.apiResponse.data && props.apiResponse.data.currentTab == props.tabNumber) {
        return (
            <Alert className="response-alert" color={props.apiResponse.success ? "success" : "danger"} style={{ width: 'fit-content' }} >
                {props.apiResponse.success ?
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} /> {t("Button.Success", { type: props.type, action: '$t(Button.' + props.apiResponse.data.buttonType + ')' })}
                    </> :
                    <>
                        <FontAwesomeIcon icon={faTimesCircle} /> {t("Button.Fail", { type: props.type, action: '$t(Button.' + props.apiResponse.data.buttonType + ')' })}
                    </> 
                }
            </Alert> )
    }
    else return null
    
}

export default connect(
    state => {
        return {
            apiResponse: state.admin.apiResponse
        }
    },
    dispatch => ({})
)(AlertPopUp);