import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import OptionsMenu from './OptionsMenu';
import StyledTable, { Styles } from './Table';

const VendorData = props => {
    const [redirect, setRedirect] = React.useState(null);
    const { t } = useTranslation();

    var cols = [];

    for (let [key, value] of Object.entries(t('CompanyCodes.Columns'))) {
        cols.push({
            Header: value, accessor: key,
            style: { fontSize: "11px" } })
    }

    const columns = [{
        Header: t("Titles.VendorData"),
        columns: cols,
        titleHeader: true
    }];

    const data = [
        {
            WithholdingType: "0",
            WithholdingTypeDesc: "1",
            WithholdingCode: "2",
            WithholdingCodeDesc: "3",
            SubjectToTax: "4"
        },
        {
            WithholdingType: "0",
            WithholdingTypeDesc: "1",
            WithholdingCode: "2",
            WithholdingCodeDesc: "3",
            SubjectToTax: "4"
        }
    ];
    
    if (redirect) {
        return <Redirect to={{ pathname: redirect }} />
    }
        return (
            <div>
                <OptionsMenu active="1" />
                <h4 style={{ textAlign: "center" }}>{t("Titles.VendorData")}</h4>

            </div>
        );
}

export default connect()(VendorData);
